<template>
  <!-- @TODO -->
  <div class="container" style="padding: 30px">
    <form action="./consent" method="POST" @submit.prevent="handleSubmit">
      <div class="log_main" v-if="sub">
        <div>
          <p>
            授权应用程序(id:
            {{ payload.clientName }})以你的身份访问以下资源：
          </p>
          <ul class="scope-list">
            <li v-for="item in payload.requestedScope" :key="item">
              <label style="font-size: large;">
                <input
                  type="checkbox"
                  :id="item"
                  :value="item"
                  name="grant_scope"
                  v-model="grantScope"
                />
                <span>{{ item }}</span>
              </label>
            </li>
          </ul>
          <div class="logBtnOuter">
            <BtnWithLoading class="btn" type="submit">授权</BtnWithLoading>
          </div>
        </div>
      </div>

      <div v-else>
        <img
          class="spinning"
          src="../assets/images/loading.svg"
          style="width: 25px; height: 25px; margin-bottom: 20px"
        />
        <div style="margin-bottom: 100px">授权中...</div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      payload: {
        clientName: 'rio',
        requestedScope: ['openid', 'profile', 'email']
      },
      challenge: '',
      csrf: '',
      sub: '',
      grantScope: []
    }
  },
  mounted() {
    this.getChallenge()
    this.prepare()
  },
  methods: {
    getChallenge() {
      if (this.$route.query && this.$route.query.consent_challenge) {
        this.challenge = this.$route.query.consent_challenge
        sessionStorage.setItem('consent.challenge', this.challenge)
      } else {
        this.challenge = sessionStorage.getItem('consent.challenge') || ''
      }
    },
    prepare() {
      this.$api
        .prepareConsent(this.challenge)
        .then(res => {
          console.log(res)
          if (res.data.errcode === '0') {
            this.csrf = this.$utils.get(res.data.data, 'CSRF')
            this.sub = this.$utils.get(res.data.data, 'Sub')
            this.payload.clientName = this.$utils.get(
              res.data.data,
              'Payload.client.client_name'
            )
            this.payload.requestedScope = this.$utils.get(
              res.data.data,
              'Payload.requested_scope'
            )
          }
        })
        .catch(err => {
          console.log(err)
          sessionStorage.clear()
          window.location.href = '/'
        })
    },
    handleSubmit() {
      let payload = {
        _csrf: this.csrf,
        challenge: this.challenge,
        sub: this.sub,
        grant_scope: this.grantScope
      }
      this.isPending = true
      this.$api
        .consent(payload)
        .then(res => {
          this.isPending = false
          console.log(res)
        })
        .catch(err => {
          console.log(err)
          sessionStorage.clear()
          window.location.href = '/'
        })
    }
  }
}
</script>
<style lang="less" scoped>
.scope-list {
  padding: 20px;
}

.scope-list label span {
  width: 100px;
  display: inline-block;
  text-align: left;
  margin-left: 10px;
}
</style>
